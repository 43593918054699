import * as msal from '@azure/msal-browser';
import Vue, { PluginObject, VueConstructor } from 'vue';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

declare module 'vue/types/vue' {
    interface Vue {
        $msal: MsalPlugin;
    }
}

export interface MsalPluginOptions {
    requiresLogin: boolean;
    clientId: string;
    loginAuthority: string;
    knownAuthority: string;
    redirectUri: string;
    scope: string;
}

let msalInstance: msal.PublicClientApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

    public isAuthenticated = false;
    public account: msal.AccountInfo | null = null;
    public requiresLogin: boolean = false;

    private pluginOptions: MsalPluginOptions = {
        requiresLogin: false,
        clientId: '',
        loginAuthority: '',
        knownAuthority: '',
        redirectUri: '',
        scope: '',
    };


    public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
        if (!options) {
            throw new Error('MsalPluginOptions must be specified');
        }
        this.pluginOptions = options;
        this.initialize(options);
        this.requiresLogin = options.requiresLogin;
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
    }


    public async signIn(applicationUri: string) {
        const loginRequest: msal.PopupRequest = {
            scopes: ['openid', 'profile', this.pluginOptions.scope],
            state: applicationUri,
        };
        try {
            //alert(JSON.stringify(loginRequest))
            const loginResponse: msal.AuthenticationResult = await msalInstance.loginPopup(loginRequest);
            this.isAuthenticated = !!loginResponse.account;
            this.account = msalInstance.getAllAccounts()[0];
        } catch (error) {
            //alert(error);
            //await this.signOut();
        }
    }

    public async signOut() {
        this.isAuthenticated = false;
        this.account = null;
        if (this.requiresLogin) {
            await msalInstance.logoutRedirect();
        }
    }

    public async acquireToken() {
        const request = {
            account: msalInstance.getAllAccounts()[0],
            scopes: [this.pluginOptions.scope],
        };
        try {
            const response = await msalInstance.acquireTokenSilent(request);
            return response.accessToken;
        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                return msalInstance.acquireTokenPopup(request).catch((popupError) => {
                    // console.log(popupError);
                });
            }
            return false;
        }
    }

    private initialize(options: MsalPluginOptions) {
        const msalConfig: msal.Configuration = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority],
                redirectUri: options.redirectUri,
            },
            cache: {
                cacheLocation: msal.BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE,
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
                        if (containsPii) {
                            return;
                        }
                        // console.log(message);
                        return;
                    },
                    piiLoggingEnabled: false,
                    logLevel: msal.LogLevel.Verbose,
                },
            },
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();
        this.account = msalInstance.getAllAccounts()[0];
    }

    private getIsAuthenticated(): boolean {
        const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }
}
